import {
    SectionHeading,
    SectionTitle,
    BYLDescription,
    BYLDescriptionAndTitle,
    BYLHeaderContainer,
    BYLHeaderImage,
} from '../styles'
import { Container, ItemContainer } from './styles'
import compressedImage from '@/helpers/compressedImage'
import { EventTracking } from '@/helpers/eventTracking'
import { useSession } from 'next-auth/react'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useEffect, useState } from 'react'

const HorizontalCardItem = dynamic(() => import('./HorizontalCardItem'))

const HorizontalContents = ({
    sectionTitle,
    contents,
    segmentId,
    viewType,
    headerViewType,
    description,
    image,
    imageMeta,
}) => {
    const { data: session } = useSession()
    const [sectionClick, onSectionClick] = useState(false)
    // const onSectionClick = () => {
    //     EventTracking(
    //         'lihatSemua_page_opened',
    //         {
    //             segmentId: segmentId,
    //             segmentTitle: sectionTitle,
    //         },
    //         session
    //     )
    //     // history.push(`/segment/${segmentId}`)
    // }
    useEffect(() => {
        if (sectionClick && session?.token) {
            EventTracking(
                'lihatSemua_page_opened',
                {
                    segmentId: segmentId,
                    segmentTitle: sectionTitle,
                },
                session
            )
        }
    }, [sectionClick, sectionTitle, segmentId, session])

    return (
        <Container>
            <Link href={`/segment/${segmentId}`} prefetch={false}>
                <a>
                    <SectionHeading>
                        {headerViewType !== 'byl' && (
                            <SectionTitle>{sectionTitle}</SectionTitle>
                        )}
                        {headerViewType == 'byl' && (
                            <BYLHeaderContainer>
                                <BYLHeaderImage
                                    loading="lazy"
                                    src={compressedImage(image, imageMeta)}
                                />
                                <BYLDescriptionAndTitle>
                                    <BYLDescription>
                                        {sectionTitle}
                                    </BYLDescription>
                                    <SectionTitle style={{ marginTop: 0 }}>
                                        {description}
                                    </SectionTitle>
                                </BYLDescriptionAndTitle>
                            </BYLHeaderContainer>
                        )}

                        <img
                            style={{ cursor: 'pointer' }}
                            src={'/img/arrow-right.svg'}
                            alt="arrow-right"
                            onClick={() => onSectionClick(true)}
                            loading="lazy"
                        />
                    </SectionHeading>
                </a>
            </Link>
            <ItemContainer>
                {contents.length &&
                    contents.map((item) => {
                        return (
                            <HorizontalCardItem
                                key={item.id + Math.random(5)}
                                id={item.id}
                                image={item.image}
                                imageMeta={item.imageMeta}
                                title={item.title}
                                subTitle={item.subTitle}
                                videoUrl={item.videoUrl}
                                author={item.author}
                                duration={item.duration}
                                entityType={item.entityType}
                                category={item.category}
                                viewType={viewType}
                                sectionTitle={sectionTitle}
                                badge={item.badge}
                            />
                        )
                    })}
            </ItemContainer>
        </Container>
    )
}

export default HorizontalContents
